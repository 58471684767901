import { Box, Container, Divider, useMediaQuery } from "@mui/material";
import React from "react";
import mr1 from "../Images/mr1.png";
import mr2 from "../Images/mr2.webp";
import mr3 from "../Images/mr3.svg";
import coin1 from "../Images/coin1.png";
import digi from "../Images/digi.jpg";
import bloom from "../Images/bloom.svg";
import aplogo from "../Images/aplogo.svg";
import Marquee from "react-fast-marquee";

export default function Partner() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box pt={matches ? 5 : 10} pb={5}>
        <Box mt={5}>
          <Marquee
            speed={50}
            pauseOnClick={true}
            pauseOnHover={true}
            gradient={false}
          >
            <Container maxWidth="xl">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <a
                  href="https://www.bloomberg.com/press-releases/2023-11-09/redai-announces-its-presale-live-to-revolutionize-ai-and-blockchain-intersection-with-red"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    width: "10%",
                    marginRight: "70px",
                  }}
                  rel="noreferrer"
                >
                  <img
                    src={bloom}
                    width="100%"
                    // style={{ marginRight: "70px" }}
                    alt=""
                  />
                </a>
                <a
                  href="https://finance.yahoo.com/news/redai-announces-presale-live-revolutionize-193000550.html"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    width: "130px",
                    marginRight: "70px",
                  }}
                  rel="noreferrer"
                >
                  <img src={mr1} width="130px" alt="" />
                </a>
                <a
                  href="https://www.benzinga.com/pressreleases/23/11/35720666/redai-a-crypto-startup-raised-20m-for-redcoin-at-a-token-valuation-of-2-5-billion"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    width: "160px",
                    marginRight: "70px",
                  }}
                  rel="noreferrer"
                >
                  <img src={mr2} width="160px" alt="" />
                </a>
                <a
                  href="https://www.marketwatch.com/press-release/redai-announces-its-presale-live-to-revolutionize-ai-and-blockchain-intersection-with-red-92f1a503?mod=search_headline"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    width: "200px",
                    marginRight: "70px",
                  }}
                  rel="noreferrer"
                >
                  <img src={mr3} width="200px" alt="" />
                </a>
                <a
                  href="https://apnews.com/press-release/ein-presswire-newsmatics/artificial-intelligence-067ec1d70f35bd90d6b3e30e0a39c4b5"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    width: "50px",
                    marginRight: "70px",
                  }}
                  rel="noreferrer"
                >
                  <img src={aplogo} width="50px" alt="" />
                </a>
                <a
                  href="https://www.digitaljournal.com/pr/news/binary-news-network/crypto-startup-redai-raised-20m-for-redcoin-at-2-5b-token-valuation"
                  target="_blank"
                  style={{ textDecoration: "none", width: "200px" }}
                  rel="noreferrer"
                >
                  <img
                    src={digi}
                    width="200px"
                    style={{ marginRight: "45px" }}
                    alt=""
                  />
                </a>
              </Box>
            </Container>
          </Marquee>
        </Box>
      </Box>
      <Container maxWidth="lg">
        <Divider
          sx={{ backgroundColor: "#262626", width: "100%", height: "1px" }}
        />
      </Container>
    </>
  );
}
