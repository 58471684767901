import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import participate from "../Images/participate.gif";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

export default function Participate() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Container maxWidth="xl">
        <Box py={matches ? 5 : 10} mt={10}>
          <Box
            sx={{
              backgroundImage: `url(${participate})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: matches ? "100% 100%" : "100% 100%",
              borderRadius: "20px",
              height: "600px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              // backgroundAttachment: "fixed",
              margin: 0,
              padding: 0,
            }}
          >
            <Container maxWidth="md">
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Regular",
                  textAlign: "center",
                  color: "#808080",
                }}
              >
                [ OUR JOURNEY HAS BEGUN ]
              </Typography>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="20"
                data-aos-duration="1500"
              >
                <Typography
                  pt={2}
                  sx={{
                    fontSize: matches ? "40px" : "65px",
                    fontFamily: "Extra Bold",
                    textAlign: "center",
                    lineHeight: matches ? "40px" : "70px",
                    color: "#fff",
                  }}
                >
                  Participate {!matches && <br />} in Presale
                </Typography>
              </div>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Typography
                  pt={2}
                  sx={{
                    fontSize: "22px",
                    fontFamily: "Regular",
                    textAlign: "center",
                    color: "#808080",
                  }}
                >
                  Join our upcoming exclusive presale for early access.
                  {!matches && <br />} Don't miss out on shaping the future with
                  us.
                </Typography>
              </div>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="40"
                data-aos-duration="2000"
              >
                <Box mt={3} display="flex" justifyContent="space-evenly">
                  <a
                    href="https://presale.red-coin.org/"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      width: matches ? "45%" : "300px",
                    }}
                    rel="noreferrer"
                  >
                    <Box
                      sx={{
                        background: "#A20127",
                        borderRadius: "92px",
                        height: "60px",
                        width: matches ? "100%" : "300px",
                        fontFamily: "Regular",
                        fontSize: matches ? "18px" : "20px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      PRESALE
                      <ArrowOutwardIcon
                        style={{
                          color: "#fff",
                          fontSize: "20px",
                          marginLeft: matches ? "5px" : "10px",
                        }}
                      />
                    </Box>
                  </a>
                  <Box
                    sx={{
                      background: "transparent",
                      borderRadius: "92px",
                      border: "2px solid #fff",
                      height: "60px",
                      width: matches ? "45%" : "300px",
                      fontFamily: "Regular",
                      fontSize: matches ? "18px" : "20px",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    INTRO{" "}
                    <PlayCircleIcon
                      style={{
                        color: "#A20127",
                        fontSize: "20px",
                        marginLeft: matches ? "5px" : "10px",
                      }}
                    />
                  </Box>
                </Box>
              </div>
            </Container>
          </Box>
        </Box>
      </Container>
    </>
  );
}
