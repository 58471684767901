import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import redtick from "../Images/redtick.png";
import wtick from "../Images/wtick.png";
import etick from "../Images/etick.png";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Roadmap() {
  const matches = useMediaQuery("(max-width:950px)");
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box py={matches ? 5 : 10}>
        <Container maxWidth="xl">
          <div
            data-aos={matches ? "fade-up" : ""}
            data-aos-delay="20"
            data-aos-duration="1500"
          >
            <Typography
              sx={{
                fontSize: matches ? "40px" : "65px",
                fontFamily: "Extra Bold",
                color: "#fff",
              }}
            >
              Roadmap
            </Typography>
          </div>
          <div
            data-aos={matches ? "fade-up" : ""}
            data-aos-delay="30"
            data-aos-duration="2000"
          >
            <Typography
              mt={1}
              sx={{
                fontSize: matches ? "18px" : "22px",
                fontFamily: "Regular",
                color: "#808080",
              }}
            >
              Our roadmap encompasses research and development, strategic
              partnerships, scaling operations, and continuous improvement.
              <br /> Through innovation and agility, we aim to become a market
              leader, delivering exceptional value to our customers.
            </Typography>
          </div>
          <Box width="100%" mt={5}>
            <Box
              pl={matches ? 2 : 4}
              sx={{ borderBottom: 0, borderColor: "#807f7f" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: " #A20127",
                  },
                }}
              >
                <Tab
                  sx={{
                    color: "#fff",
                    "&.Mui-selected": {
                      color: "#A20127",
                    },
                  }}
                  label={
                    <Typography
                      sx={{
                        fontFamily: "Regular",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      Roadmap <br />
                      2023
                    </Typography>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    color: "#fff",
                    "&.Mui-selected": {
                      color: "#A20127",
                    },
                  }}
                  label={
                    <Typography
                      sx={{
                        fontFamily: "Regular",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      Roadmap <br />
                      2024
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Grid container spacing={5} mt={1}>
                <Grid item xs={12} md={4}>
                  <div
                    data-aos={matches ? "fade-up" : ""}
                    data-aos-delay="30"
                    data-aos-duration="2000"
                    style={{
                      height: matches ? "300px" : "232px",
                    }}
                  >
                    <Box
                      sx={{
                        background:
                          "linear-gradient(180deg, #242424 0%, #2F2F2F 100%),linear-gradient(0deg, #000000, #000000)",
                        borderRadius: "20px",
                        width: "100%",
                        height: matches ? "300px" : "232px",
                        color: "#808080",
                        "&:hover": {
                          background: "#A20127",
                          color: "#fff",
                          boxShadow: "0px 28px 181px 0px #A2012799",
                        },
                      }}
                    >
                      <Box p={3}>
                        <Typography
                          sx={{
                            fontFamily: "Regular",
                            fontSize: "32px",
                            color: "#fff",
                          }}
                        >
                          Q1 2023
                        </Typography>
                        <Box display="flex" alignItems="center" mt={3}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Initial Project Plan
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={1}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Establish the Core Team of Developers and Experts in
                            AI and Blockchain Technology
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                  <div
                    data-aos={matches ? "fade-up" : ""}
                    data-aos-delay="30"
                    data-aos-duration="2000"
                    style={{
                      height: matches ? "390px" : "350px",
                    }}
                  >
                    <Box
                      mt={2}
                      sx={{
                        background:
                          "linear-gradient(180deg, #242424 0%, #2F2F2F 100%),linear-gradient(0deg, #000000, #000000)",
                        borderRadius: "20px",
                        width: "100%",
                        height: matches ? "390px" : "350px",
                        color: "#808080",
                        "&:hover": {
                          background: "#A20127",
                          color: "#fff",
                          boxShadow: "0px 28px 181px 0px #A2012799",
                        },
                      }}
                    >
                      <Box p={3}>
                        <Typography
                          sx={{
                            fontFamily: "Regular",
                            fontSize: "32px",
                            color: "#fff",
                          }}
                        >
                          Q2 2023
                        </Typography>
                        <Box display="flex" alignItems="center" mt={3}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Planning and Developing Red Leading AI Model
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={1}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Establish company structure, complete tokenomics
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={1}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Conduct online events and AMAs for investors
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    data-aos={matches ? "fade-up" : ""}
                    data-aos-delay="30"
                    data-aos-duration="2000"
                    style={{
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        background:
                          "linear-gradient(180deg, #242424 0%, #2F2F2F 100%),linear-gradient(0deg, #000000, #000000)",
                        borderRadius: "20px",
                        width: "100%",
                        height: "100%",
                        color: "#808080",
                        "&:hover": {
                          background: "#A20127",
                          color: "#fff",
                          boxShadow: "0px 28px 181px 0px #A2012799",
                        },
                      }}
                    >
                      <Box p={3}>
                        <Typography
                          sx={{
                            fontFamily: "Regular",
                            fontSize: "32px",
                            color: "#fff",
                          }}
                        >
                          Q3 2023
                        </Typography>
                        <Box display="flex" alignItems="center" mt={3}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Developing our Red AI Model
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Raised 20 Million Fund
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Begin growing social media presence.
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Partnering with Strategic Investors (Private Round)
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    data-aos={matches ? "fade-up" : ""}
                    data-aos-delay="30"
                    data-aos-duration="2000"
                    style={{
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        background:
                          "linear-gradient(180deg, #242424 0%, #2F2F2F 100%),linear-gradient(0deg, #000000, #000000)",
                        borderRadius: "20px",
                        width: "100%",
                        height: "100%",
                        color: "#808080",
                        "&:hover": {
                          background: "#A20127",
                          color: "#fff",
                          boxShadow: "0px 28px 181px 0px #A2012799",
                        },
                      }}
                    >
                      <Box p={3}>
                        <Typography
                          sx={{
                            fontFamily: "Regular",
                            fontSize: "32px",
                            color: "#fff",
                          }}
                        >
                          Q4 2023
                        </Typography>
                        <Box display="flex" alignItems="center" mt={3}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Minting $RED on Ethereum Mainnet
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Red AI Press Release.
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Red AI Marketing at Times Square NYC.
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img
                            src={redtick}
                            width="26px"
                            height="26px"
                            alt=""
                          />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Collaboration with Youtubers and Influencers
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Grid container spacing={5} mt={1}>
                <Grid item xs={12} md={4}>
                  <div
                    data-aos={matches ? "fade-up" : ""}
                    data-aos-delay="30"
                    data-aos-duration="2000"
                    style={{
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        background:
                          "linear-gradient(180deg, #242424 0%, #2F2F2F 100%),linear-gradient(0deg, #000000, #000000)",
                        borderRadius: "20px",
                        width: "100%",
                        height: "100%",
                        color: "#808080",
                        "&:hover": {
                          background: "#A20127",
                          color: "#fff",
                          boxShadow: "0px 28px 181px 0px #A2012799",
                        },
                      }}
                    >
                      <Box p={3}>
                        <Typography
                          sx={{
                            fontFamily: "Regular",
                            fontSize: "32px",
                            color: "#fff",
                          }}
                        >
                          Q1 2024
                        </Typography>
                        <Box display="flex" alignItems="center" mt={3}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Lay groundwork for future company initiatives.
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Building Global Community: Many Events, Advisors,
                            and Ambassadors
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Raised 50 Million Fund
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Upgrading AI Model
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Collaboration with High end brands and Firms
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Expansion of RedAI Team
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Marketing Worldwide
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    data-aos={matches ? "fade-up" : ""}
                    data-aos-delay="30"
                    data-aos-duration="2000"
                    style={{
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        background:
                          "linear-gradient(180deg, #242424 0%, #2F2F2F 100%),linear-gradient(0deg, #000000, #000000)",
                        borderRadius: "20px",
                        width: "100%",
                        height: "100%",
                        color: "#808080",
                        "&:hover": {
                          background: "#A20127",
                          color: "#fff",
                          boxShadow: "0px 28px 181px 0px #A2012799",
                        },
                      }}
                    >
                      <Box p={3}>
                        <Typography
                          sx={{
                            fontFamily: "Regular",
                            fontSize: "32px",
                            color: "#fff",
                          }}
                        >
                          Q2 2024
                        </Typography>
                        <Box display="flex" alignItems="center" mt={3}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            DEX/CEX Listing (Public Launch)
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Pre-Listing CMC and CG Listing
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Integrating Staking
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            Upgrading RedAI model with Trading Tools (charts,
                            TA, News, Tracking)
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <img src={wtick} width="26px" height="26px" alt="" />
                          <Typography
                            pl={1}
                            sx={{
                              fontFamily: "Regular",
                              fontSize: "18px",
                            }}
                          >
                            AI Integration in the Wallet
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </CustomTabPanel>
          </Box>
        </Container>
      </Box>
    </>
  );
}
