import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import Badge from "./pages/Badge";
import Contract from "./pages/Contract";
import Foundation from "./pages/Foundation";
import UseCases from "./pages/UseCases";
import FeaturedSlider from "./pages/FeaturedSlider";
import Ecosystem from "./pages/Ecosystem";
import Roadmap from "./pages/Roadmap";
import RedFoundation from "./pages/RedFoundation";
import Properties from "./pages/Properties";
import Focus from "./pages/Focus";
import Participate from "./pages/Participate";
import Partner from "./pages/Partner";
import Footer from "./pages/Footer";
import RedAiApplications from "./pages/applicationsSectons/RedAiApplications";
import JoinMarketPlace from "./pages/JoinMarketPlace";
import CoreElement from "./pages/CoreElement";
import AiEcoSystem from "./pages/AiEcoSystem";
import Works from "./pages/Works";
function App() {
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 56) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  return (
    <>
      {/* <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} /> */}
      <Header />
      <Badge />
      <HeroSection />

      <Contract />

  
      <RedAiApplications />

      <JoinMarketPlace />
      <CoreElement />
      <AiEcoSystem />
      <Foundation />
    
      <UseCases />
      <FeaturedSlider />
      <Focus />
      <RedFoundation />
      <Works />
      {/* <Ecosystem /> */}
      <Roadmap />
      {/* <Hiring />   */}

      {/* <Properties /> */}
  
     
      <Participate />
      <Partner />
      <Footer />
    </>
  );
}

export default App;
