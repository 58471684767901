import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { workImg } from "../components/SmallComponents/Images";

function Works() {
  const matches = useMediaQuery("(max-width:950px)");
  return (
    <Box mt={{ xs: 10, sm: 18 }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: matches ? "32px" : "65px",
              fontFamily: "Extra Bold",
              color: "#fff",

              textAlign: "center",
            }}
          >
            The solution & How it works
          </Typography>
          <Typography
            mt={1}
            sx={{
              fontSize: matches ? "18px" : "22px",
              fontFamily: "Regular",
              color: "#808080",
              width: { xs: "100%", sm: "70%", md: "50%" },
              textAlign: "center",
            }}
          >
            RedAI is a decentralized artificial intelligence platform that will
            bring new intelligent algorithms and services to market through
            collaboration between enterprises, infrastructure providers, data
            providers, and AI innovators.
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Box
              component="img"
              alt=""
              src={workImg}
              width={{ xs: "90%", sm: "80%", md: "70%" }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Works;
