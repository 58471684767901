import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import wlogo from "../Images/wlogo.png";

export default function Focus() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Container maxWidth="xl">
        <Box py={matches ? 5 : 10}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={matches ? 2 : 8}
          >
            <div
              data-aos={matches ? "fade-up" : ""}
              data-aos-delay="20"
              data-aos-duration="1500"
            >
              <Typography
                sx={{
                  fontSize: matches ? "32px" : "65px",
                  fontFamily: "Extra Bold",
                  color: "#fff",
                }}
              >
                Vision, Mission & {!matches && <br />}
                Focus
              </Typography>
            </div>
            {/* <img width="80px" height="80px" src={wlogo} alt="" /> */}
          </Box>
          <Grid container spacing={5} mt={1}>
            <Grid item xs={12} md={6}>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="20"
                data-aos-duration="1500"
              >
                <Box display="flex" alignItems="center">
                  <Box
                    mr={2}
                    sx={{
                      width: "70px",
                      height: "70px",
                      background:
                        "linear-gradient(180deg, #242424 0%, #2F2F2F 100%)",
                      borderRadius: "50%",
                      fontFamily: "Regular",
                      color: "#fff",
                      fontSize: "38px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    01
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      color: "#fff",
                      fontSize: matches ? "40px" : "48px",
                    }}
                  >
                    Vision
                  </Typography>
                </Box>
              </div>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Typography
                  mt={2}
                  sx={{
                    fontFamily: "Regular",
                    color: "#808080",
                    fontSize: matches ? "20px" : "22px",
                  }}
                >
                  We are dedicated to a mission that socks to fully
                  revolutionize the way people interact with blockchain
                  technology and cryptocurrencies while utilizing the enormous
                  potential of artificial intelligence (AI).
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="20"
                data-aos-duration="1500"
              >
                <Box display="flex" alignItems="center">
                  <Box
                    mr={2}
                    sx={{
                      width: "70px",
                      height: "70px",
                      background:
                        "linear-gradient(180deg, #242424 0%, #2F2F2F 100%)",
                      borderRadius: "50%",
                      fontFamily: "Regular",
                      color: "#fff",
                      fontSize: "38px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    02
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      color: "#fff",
                      fontSize: matches ? "40px" : "48px",
                    }}
                  >
                    Mission
                  </Typography>
                </Box>
              </div>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Typography
                  mt={2}
                  sx={{
                    fontFamily: "Regular",
                    color: "#808080",
                    fontSize: matches ? "20px" : "22px",
                  }}
                >
                  Our mission revolve around empowering users in the quickly
                  changing cryptocurrency market by providing them with the
                  knowledge and tools ther need to make wise decisions. In order
                  to accomplish this, we provide an AI -powered platform that is
                  meticulously customized to satisfy each user’s particular
                  requirement
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="20"
                data-aos-duration="1500"
              >
                <Box display="flex" alignItems="center">
                  <Box
                    mr={2}
                    sx={{
                      width: "70px",
                      height: "70px",
                      background:
                        "linear-gradient(180deg, #242424 0%, #2F2F2F 100%)",
                      borderRadius: "50%",
                      fontFamily: "Regular",
                      color: "#fff",
                      fontSize: "38px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    03
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      color: "#fff",
                      fontSize: matches ? "40px" : "48px",
                    }}
                  >
                    Focus
                  </Typography>
                </Box>
              </div>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Typography
                  mt={2}
                  sx={{
                    fontFamily: "Regular",
                    color: "#808080",
                    fontSize: matches ? "20px" : "22px",
                  }}
                >
                  Our focus is to establish a future in which platforms driven
                  by artificial intelligence (AI) are easily navigable,
                  user-friendly, and consistently deliver precise, trustworthy
                  information, assistance, and resources within the constantly
                  evolving domain of cryptocurrencies and blockchain technology.
                </Typography>
              </div>
            </Grid>
            {/* <Grid item xs={12} md={6} display="flex" alignItems="center">
              <Typography
                mt={2}
                sx={{
                  fontFamily: "Regular",
                  color: "#fff",
                  fontSize: matches ? "18px" : "20px",
                }}
              >
                LEARN MORE ABOUT TEAM
                <ArrowOutwardIcon
                  style={{
                    color: "red",
                    fontSize: "25px",
                    marginLeft: "10px",
                  }}
                />
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
        <Divider
          sx={{ backgroundColor: "#262626", width: "100%", height: "1px" }}
        />
      </Container>
    </>
  );
}
