import React from "react";
import { Box, Container, Grid } from "@mui/material";
import ApplicationsCards from "../../components/SmallComponents/ApplicationsCards";
import {
  applicationCard1,
  applicationCard2,
  applicationCard3,
  applicationCard4,
  applicationCard5,
  applicationCard6,
  applicationCard7,
  applicationCard8,
  applicationCard9,
  applicationCard10,
  applicationCard11,
  applicationCard12,
  applicationCard13,
  applicationCard14,
  applicationCard15,
  applicationCard16,
  applicationCard17,
  applicationCard18,
  applicationCard19,
  applicationCard20,
  applicationCard21,
  applicationCard22,
  applicationCard23,
  applicationCard24,
} from "../../components/SmallComponents/Images";

const cardsArray = [
  {
    logo: applicationCard1,
    title: "RedAI Art Bot",
    pending: false,
  },
  {
    logo: applicationCard2,
    title: "RedAI Meme Bot",
    pending: false,
  },
  {
    logo: applicationCard3,
    title: "RedAI QR Bot",
    pending: false,
  },
  {
    logo: applicationCard4,
    title: "RedAI Sound Bot",
    pending: false,
  },
  {
    logo: applicationCard5,
    title: "Trading Crypto Bot",
    pending: false,
  },
  {
    logo: applicationCard6,
    title: "Drug Discovery",
    pending: false,
  },
  {
    logo: applicationCard7,
    title: "RedAI Chat Bot",
    pending: true,
  },
  {
    logo: applicationCard8,
    title: "Manga Generator",
    pending: true,
  },
  {
    logo: applicationCard9,
    title: "3d Model Generator",
    pending: true,
  },
  {
    logo: applicationCard10,
    title: "Mind Script",
    pending: true,
  },
  {
    logo: applicationCard11,
    title: "Poster Generator",
    pending: true,
  },
  {
    logo: applicationCard12,
    title: "Voice synthesizer",
    pending: true,
  },
  {
    logo: applicationCard13,
    title: "Face Generator",
    pending: true,
  },
  {
    logo: applicationCard14,
    title: "Avatar Generator",
    pending: true,
  },
  {
    logo: applicationCard15,
    title: "3d Human Generator",
    pending: true,
  },
  {
    logo: applicationCard16,
    title: "Face Recognition",
    pending: true,
  },
  {
    logo: applicationCard17,
    title: "Deep Face",
    pending: true,
  },
  {
    logo: applicationCard18,
    title: "Animation Creation",
    pending: true,
  },
  {
    logo: applicationCard19,
    title: "Text to image",
    pending: true,
  },
  {
    logo: applicationCard20,
    title: "Media Enhancer",
    pending: true,
  },
  {
    logo: applicationCard21,
    title: "Graphic Editor",
    pending: true,
  },
  {
    logo: applicationCard22,
    title: "Codefabric AI",
    pending: true,
  },
  {
    logo: applicationCard23,
    title: "Deck Generator",
    pending: true,
  },
  {
    logo: applicationCard24,
    title: "Prompt Generator",
    pending: true,
  },
];

function WebApplicationsSection() {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} justifyContent={"center"} my={4}>
        {cardsArray?.map(({ logo, title, pending }, i) => (
          <Grid item xs={2} key={title}>
            {/* <Box px={3}> */}
            <ApplicationsCards
              logo={logo}
              title={title}
              pending={pending}
              index={i}
            />
            {/* </Box> */}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default WebApplicationsSection;
