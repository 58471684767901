import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { marketPlace } from "../components/SmallComponents/Images";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Navigation } from "swiper/modules";

const arrowBoxStyle = {
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mt: -2,
};

function JoinMarketPlace() {
  const matches = useMediaQuery("(max-width:950px)");
  const matchesTwo = useMediaQuery("(max-width:600px)");
  return (
    <Box mt={{ xs: 10, sm: 18 }}>
      <Container maxWidth="xl">
        <Typography
          sx={{
            fontSize: matches ? "32px" : "65px",
            fontFamily: "Extra Bold",
            color: "#fff",
          }}
        >
          Join our marketplace
        </Typography>
        <Typography
          mt={1}
          sx={{
            fontSize: matches ? "18px" : "22px",
            fontFamily: "Regular",
            color: "#808080",
          }}
        >
          Venture into RedAI Apps Marketplace Your Gateway to Cutting-Edge
          Solutions.
        </Typography>
        <Grid container justifyContent={"center"} my={4}>
          <Grid item xs={2}>
            <Box sx={arrowBoxStyle}>
              <ChevronLeftIcon
                className="swiper-button-prev-1"
                style={{
                  background: "#A201274D",
                  boxShadow: "0px 10px 28.299999237060547px 0px #A201274D",
                  width: matchesTwo ? "30px" : "60px",
                  height: matchesTwo ? "30px" : "60px",
                  borderRadius: "50px",
                  cursor: "pointer",
                  padding: "7px",
                  position: "relative",
                  color: "white",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box>
              <Swiper
                slidesPerView={1}
                //   pagination={{
                //     dynamicBullets: true,
                //   }}
                //   modules={[Pagination]}
                navigation={{
                  nextEl: ".swiper-button-next-1",
                  prevEl: ".swiper-button-prev-1",
                }}
                modules={[Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <Box
                    component={"img"}
                    alt=""
                    src={marketPlace}
                    width={"100%"}
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <Box
                    component={"img"}
                    alt=""
                    src={marketPlace}
                    width={"100%"}
                  />
                </SwiperSlide>
              </Swiper>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={arrowBoxStyle}>
              {" "}
              <ChevronRightIcon
                className="swiper-button-next-1"
                style={{
                  background: "#A201274D",

                  boxShadow: "0px 10px 28.299999237060547px 0px #A201274D",
                  width: matchesTwo ? "30px" : "60px",
                  height: matchesTwo ? "30px" : "60px",
                  borderRadius: "50px",
                  marginLeft: "10px",
                  cursor: "pointer",
                  padding: "7px",
                  position: "relative",
                  color: "white",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default JoinMarketPlace;
