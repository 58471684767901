import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  ecoSystem1,
  ecoSystem2,
  ecoSystem3,
  ecoSystem4,
  ecoSystem5,
  ecoSystem6,
  ecoSystem7,
  ecoSystem8,
} from "../components/SmallComponents/Images";

const frstParaStyle = {
  fontSize: "18px",
  fontFamily: "Regular",
  color: "#808080",
};
const headingStyle = {
  fontSize: "22px",
  fontFamily: "Regular",
  color: "#fff",
  mt: 1,
};
const scndParaStyle = {
  fontSize: "18px",
  fontFamily: "Regular",
  color: "#808080",
  mt: 1,
};

function AiEcoSystem() {
  const matches = useMediaQuery("(max-width:950px)");
  return (
    <Box mt={{ xs: 10, sm: 18 }}>
      <Container maxWidth="xl">
        <Typography
          sx={{
            fontSize: matches ? "32px" : "65px",
            fontFamily: "Extra Bold",
            color: "#fff",
            textAlign: "center",
          }}
        >
          Red AI Ecosystem
        </Typography>
        <Typography
          mt={1}
          sx={{
            fontSize: matches ? "18px" : "22px",
            fontFamily: "Regular",
            color: "#808080",
            textAlign: "center",
          }}
        >
          Secure your seat into the new internet of AI
        </Typography>

        <Grid container spacing={4} justifyContent="center" my={6}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                borderRadius: "12px",
                // px: 2,
                // py: 3,
                background: "#141414",
                height: "100%",
                "&:hover": {
                  background: "#A20127",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box component="img" alt="" src={ecoSystem1} width={"170px"} />
              </Box>
              <Box px={{ xs: 2, sm: 4 }} mt={-2} mb={2}>
                <Typography sx={frstParaStyle}>Make a smart</Typography>
                <Typography sx={headingStyle}>Investment</Typography>
                <Typography sx={scndParaStyle}>
                  In the next BIG thing <br /> since internet
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                borderRadius: "12px",
                px: 2,

                background: "#141414",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                alignItems: "end",
                "&:hover": {
                  background: "#A20127",
                },
              }}
            >
              <Box
                pt={{ xs: 6, sm: 2 }}
                pb={{ xs: 1, sm: 2 }}
                width={{ xs: "120%", sm: "100%" }}
              >
                <Typography sx={frstParaStyle}>Unlock</Typography>
                <Typography sx={headingStyle}>Access</Typography>
                <Typography sx={scndParaStyle}>
                  To high-tech <br /> competitive edge.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  component="img"
                  alt=""
                  src={ecoSystem2}
                  width={{ xs: "80%", sm: "80%" }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                borderRadius: "12px",
                // px: 2,
                // py: 3,
                background: "#141414",
                height: "100%",
                "&:hover": {
                  background: "#A20127",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  px: 2,
                }}
              >
                <Box
                  component="img"
                  alt=""
                  src={ecoSystem3}
                  width={{ xs: "160px", sm: "190px" }}
                />
              </Box>
              <Box px={{ xs: 2, sm: 4 }} mt={-4} mb={2}>
                <Typography sx={frstParaStyle}>Join as</Typography>
                <Typography sx={headingStyle}>Innovator</Typography>
                <Typography sx={scndParaStyle}>
                  To build and monetize AI-Apps
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                borderRadius: "12px",
                px: 2,

                background: "#141414",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                alignItems: "end",
                "&:hover": {
                  background: "#A20127",
                },
              }}
            >
              <Box
                pt={{ xs: 9, sm: 2 }}
                pb={2}
                px={{ xs: 0, sm: 2 }}
                width={{ xs: "120%", sm: "100%" }}
              >
                <Typography sx={frstParaStyle}>Deploy</Typography>
                <Typography sx={headingStyle}>Infrastructure</Typography>
                <Typography sx={scndParaStyle}>computing power</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",

                  height: "100%",
                  pr: { xs: 1, sm: 3 },
                }}
              >
                <Box
                  component="img"
                  alt=""
                  src={ecoSystem4}
                  width={{ xs: "100%", sm: "90%" }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                borderRadius: "12px",
                px: 2,

                background: "#141414",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                alignItems: "end",
                "&:hover": {
                  background: "#A20127",
                },
              }}
            >
              <Box
                pt={{ xs: 9, sm: 2 }}
                px={{ xs: 0, sm: 2 }}
                pb={2}
                width={{ xs: "150%", sm: "100%" }}
              >
                <Typography sx={frstParaStyle}>Publish</Typography>
                <Typography sx={headingStyle}>Data</Typography>
                <Typography sx={scndParaStyle}>next-gen AI-apps</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  py: 4,
                  height: "100%",
                  pr: { xs: 0, sm: 3 },
                }}
              >
                <Box component="img" alt="" src={ecoSystem5} width={"90%"} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                borderRadius: "12px",
                // px: 2,
                // py: 3,
                background: "#141414",
                height: "100%",
                "&:hover": {
                  background: "#A20127",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  component="img"
                  alt=""
                  src={ecoSystem6}
                  width={{ xs: "160px", sm: "190px" }}
                />
              </Box>
              <Box px={{ xs: 2, sm: 4 }} mt={-4} mb={2}>
                <Typography sx={frstParaStyle}>Become</Typography>
                <Typography sx={headingStyle}>Ambassador</Typography>
                <Typography sx={scndParaStyle}>
                  To create bridges <br /> and apportunities
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                borderRadius: "12px",
                px: 2,

                background: "#141414",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                alignItems: "end",
                "&:hover": {
                  background: "#A20127",
                },
              }}
            >
              <Box
                pt={{ xs: 6, sm: 2 }}
                pb={{ xs: 1, sm: 2 }}
                width={{ xs: "120%", sm: "100%" }}
              >
                <Typography sx={frstParaStyle}>Join as</Typography>
                <Typography sx={headingStyle}>Validator</Typography>
                <Typography sx={scndParaStyle}>
                  To source network <br /> operation
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  component="img"
                  alt=""
                  src={ecoSystem7}
                  width={{ xs: "90%", sm: "100%" }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                borderRadius: "12px",
                pl: 2,

                background: "#141414",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                alignItems: "end",
                "&:hover": {
                  background: "#A20127",
                },
              }}
            >
              <Box
                pt={{ xs: 6, sm: 2 }}
                pb={{ xs: 1, sm: 2 }}
                width={{ xs: "120%", sm: "100%" }}
              >
                <Typography sx={frstParaStyle}>Become</Typography>
                <Typography sx={headingStyle}>Promoter</Typography>
                <Typography sx={scndParaStyle}>
                  To help <br />
                  community <br />
                  growth
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  component="img"
                  alt=""
                  src={ecoSystem8}
                  width={{ xs: "90%", sm: "100%" }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default AiEcoSystem;
