import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { StyledText } from "../components/SmallComponents/AppComponents";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import logo from "../Images/logo.png";
import tele from "../Images/tele.png";
import x from "../Images/x.png";
import med from "../Images/med.png";
import linkin from "../Images/linkin.png";
import github from "../Images/github.png";
import discord from "../Images/discord.png";

export default function Footer() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box py={matches ? 5 : 10}>
        <Container maxWidth="xl">
          <Grid container spacing={5} px={matches ? 0 : 5}>
            <Grid item xs={12} md={2.66}>
              <StyledText mt={2}>HOME</StyledText>
              <a
                href="https://presale.red-coin.org/"
                target="_blank"
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <StyledText mt={2} display="flex">
                  PRESALE{" "}
                  <Box
                    ml={1}
                    style={{
                      width: "50px",
                      height: "25px",
                      background: "#86EFAC",
                      borderRadius: "56px",
                      color: "#000",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    LIVE
                  </Box>
                </StyledText>
              </a>
              <StyledText mt={2}>TEAM</StyledText>
              <StyledText mt={2}>CONTRACT</StyledText>
            </Grid>
            <Grid item xs={12} md={2.66}>
              <StyledText mt={2}>COMMUNITY</StyledText>
              <StyledText mt={2}>SOLUTION</StyledText>
              <StyledText mt={2}>FOUNDATION</StyledText>
              <StyledText mt={2}>MISSION</StyledText>
            </Grid>
            <Grid item xs={12} md={2.66}>
              <StyledText mt={2}>TOKEN</StyledText>
              <StyledText mt={2}>ROADMAP</StyledText>
              <StyledText mt={2}>DOCS</StyledText>
              <StyledText mt={2}>ECOSYSTEM</StyledText>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <img width="43px" src={logo} alt="" />
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: matches ? "20px" : "30px",
                    pl: 1,
                    color: "#fff",
                  }}
                >
                  RedAI Core
                </Typography>
              </Box>
              <Typography
                mt={4}
                sx={{
                  fontFamily: "Regular",
                  fontSize: "20px",
                  color: "#808080",
                  textAlign: "center",
                }}
              >
                101 Sheikh Zayed Rd-Trade Center-DIFC
                <br />
                Dubai, United Arab Emirates
              </Typography>
              <Box display="flex" justifyContent="center" mt={3}>
                <a
                  href="https://t.me/redai_global"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Box
                    mr={1.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: matches ? "40px" : "57px",
                      height: matches ? "40px" : "57px",
                      background: "#404040",
                      borderRadius: "50%",
                    }}
                  >
                    <img src={tele} width={matches ? "20px" : "29px"} alt="" />
                  </Box>
                </a>
                <a
                  href="https://x.com/redcoin_ai?s=11"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Box
                    mr={1.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: matches ? "40px" : "57px",
                      height: matches ? "40px" : "57px",
                      background: "#404040",
                      borderRadius: "50%",
                    }}
                  >
                    <img src={x} width={matches ? "20px" : "29px"} alt="" />
                  </Box>
                </a>
                <Box
                  mr={1.5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: matches ? "40px" : "57px",
                    height: matches ? "40px" : "57px",
                    background: "#404040",
                    borderRadius: "50%",
                  }}
                >
                  <img src={med} width={matches ? "20px" : "29px"} alt="" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {!matches && (
        <Container>
          <Divider
            sx={{
              backgroundColor: "#262626",
              width: "100%",
              height: "2px",
              marginTop: matches ? "25px" : "50px",
            }}
          />
          <Box
            py={matches ? 5 : 10}
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Regular",
                textAlign: "center",
                color: "#6F6F6F",
              }}
            >
              ©2023 ― RedAI Core.ALL RIGHTS RESERVED
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Regular",
                textAlign: "center",
                color: "#6F6F6F",
              }}
            >
              TERMS & CONDITIONS•PRIVACY POLICY
            </Typography>
          </Box>
          <Divider
            sx={{ backgroundColor: "#262626", width: "100%", height: "2px" }}
          />
          <Box pb={matches ? 5 : 15} />
        </Container>
      )}
    </>
  );
}
