import { Box, Typography } from "@mui/material";
import React from "react";
import { applicationCard1 } from "./Images";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

function ApplicationsCards({ logo, title, pending, index }) {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#141414",
          borderRadius: "12px",
          p: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "220px",
          position: "relative",
        }}
      >
        <Box mt={1.5}>
          <Box
            component="img"
            alt=""
            src={logo}
            width={{ xs: "80px", sm: "100px" }}
          />
        </Box>
        <Box mt={pending ? 2 : 0}>
          <Typography
            sx={{
              fontSize: pending ? "15px" : "13px",
              fontFamily: "Regular",
              textAlign: "center",
              fontWeight: pending ? "700" : "400",
              color: "#fff",
              mt: pending ? 0 : 6,
            }}
          >
            {title}
            {!pending ? (
              <span>
                {" "}
                <ArrowOutwardIcon
                  sx={{
                    fontSize: "14px",
                    color: "red",
                  }}
                />
              </span>
            ) : null}
          </Typography>
        </Box>
        {pending ? (
          <Box
            sx={{
              fontSize: "14px",
              fontFamily: "Regular",
              color: "#fff",
              background: "#8901224D",
              boxShadow: "0px -6px 20.200000762939453px 0px #000000CC",
              px: 2,
              py: 1,
              borderRadius: "8px",
              textAlign: "center",
              position: "absolute",
              bottom: "-20px",
              left: "23px",
            }}
          >
            Available soon
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default ApplicationsCards;
