import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import chain from "../Images/chain.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ToastNotify } from "../components/SmallComponents/AppComponents";
import CopyToClipboard from "react-copy-to-clipboard";

export default function Contract() {
  const matches = useMediaQuery("(max-width:950px)");
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  return (
    <Box py={matches ? 5 : 10}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Container maxWidth="xl">
        <div
          data-aos={matches ? "fade-up" : ""}
          data-aos-delay="30"
          data-aos-duration="2000"
        >
          <Box
            sx={{
              background: "#161616",
              borderRadius: "20px",
            }}
          >
            <Grid
              container
              spacing={3}
              p={4}
              display="flex"
              alignItems="center"
            >
              <Grid item xs={12} md={5}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Regular",
                    color: "#5C5C5C",
                  }}
                >
                  CONTRACT ADDRESS
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    fontSize: "18px",
                    fontFamily: "Regular",
                    color: "#fff",
                  }}
                >
                  {matches
                    ? "0x957d6B9....3E903e667"
                    : "0x957d6B965E3e0Aaf2d39a666575d69f3E903e667"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={3.5}
                display="flex"
                flexDirection="column"
                alignItems={matches ? "flex-start" : "center"}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Regular",
                    color: "#5C5C5C",
                  }}
                >
                  NETWORK
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    fontSize: "18px",
                    fontFamily: "Regular",
                    color: "#fff",
                  }}
                >
                  Ethereum ERC-20
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={3.5}
                display="flex"
                justifyContent={matches ? "flex-start" : "flex-end"}
                alignItems="center"
              >
                <img src={chain} width="40px" height="40px" alt="" />
                <Box
                  ml={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "190px",
                    height: "50px",
                    border: "1px solid #5C5C5C",
                    borderRadius: "25px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Regular",
                      color: "#5C5C5C",
                    }}
                  >
                    COPY ADDRESS
                  </Typography>
                  <CopyToClipboard
                    text={"0x957d6B965E3e0Aaf2d39a666575d69f3E903e667"}
                    onCopy={() => {
                      setAlertState({
                        open: true,
                        message: `Contract Address Copied.`,
                        severity: "success",
                      });
                    }}
                  >
                    <ContentCopyIcon
                      style={{
                        marginLeft: "10px",
                        color: "#5C5C5C",
                        cursor: "pointer",
                      }}
                    />
                  </CopyToClipboard>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Container>
    </Box>
  );
}
