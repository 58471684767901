import { Box, Typography } from "@mui/material";
import React from "react";

export default function Badge() {
  return (
    <Box
      sx={{
        background: " #A20127",
        py: 1,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Regular",
          fontSize: { xs: "16px", md: "18px" },
          textAlign: "center",
          color: "#fff",
        }}
      >
        {" "}
        Our presale is Finally LIVE | Invest now{" "}
      </Typography>
    </Box>
  );
}
