import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import WebApplicationsSection from "./WebApplicationsSection";
import MobileApplicationsSection from "./MobileApplicationsSection";

function RedAiApplications() {
  const matches = useMediaQuery("(max-width:950px)");
  const matchesTwo = useMediaQuery("(max-width:1220px)");
  return (
    <Box>
      <Container maxWidth="xl">
        <Typography
          sx={{
            fontSize: matches ? "32px" : "65px",
            fontFamily: "Extra Bold",
            color: "#fff",
          }}
        >
          Top AI Applications
        </Typography>
        <Typography
          mt={1}
          sx={{
            fontSize: matches ? "18px" : "22px",
            fontFamily: "Regular",
            color: "#808080",
          }}
        >
          A suite of AI tools tailored to meet the dynamic needs of today’s
          modern world
        </Typography>
        {matchesTwo ? (
          <MobileApplicationsSection />
        ) : (
          <WebApplicationsSection />
        )}
      </Container>
    </Box>
  );
}

export default RedAiApplications;
