import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { Box, Container, Grid, useMediaQuery } from "@mui/material";

import {
  applicationCard1,
  applicationCard2,
  applicationCard3,
  applicationCard4,
  applicationCard5,
  applicationCard6,
  applicationCard7,
  applicationCard8,
  applicationCard9,
  applicationCard10,
  applicationCard11,
  applicationCard12,
  applicationCard13,
  applicationCard14,
  applicationCard15,
  applicationCard16,
  applicationCard17,
  applicationCard18,
  applicationCard19,
  applicationCard20,
  applicationCard21,
  applicationCard22,
  applicationCard23,
  applicationCard24,
} from "../../components/SmallComponents/Images";
import ApplicationsCards from "../../components/SmallComponents/ApplicationsCards";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// eslint-disable-next-line no-sparse-arrays
const cardsArray = [
  [
    {
      logo: applicationCard1,
      title: "RedAI Art Bot",
      pending: false,
    },
    {
      logo: applicationCard2,
      title: "RedAI Meme Bot",
      pending: false,
    },
    {
      logo: applicationCard3,
      title: "RedAI QR Bot",
      pending: false,
    },
    {
      logo: applicationCard4,
      title: "RedAI Sound Bot",
      pending: false,
    },
    {
      logo: applicationCard5,
      title: "Trading Crypto Bot",
      pending: false,
    },
    {
      logo: applicationCard6,
      title: "Drug Discovery",
      pending: false,
    },
    {
      logo: applicationCard7,
      title: "RedAI Chat Bot",
      pending: true,
    },
    {
      logo: applicationCard8,
      title: "Manga Generator",
      pending: true,
    },
  ],
  [
    {
      logo: applicationCard9,
      title: "3d Model Generator",
      pending: true,
    },
    {
      logo: applicationCard10,
      title: "Mind Script",
      pending: true,
    },
    {
      logo: applicationCard11,
      title: "Poster Generator",
      pending: true,
    },
    {
      logo: applicationCard12,
      title: "Voice synthesizer",
      pending: true,
    },
    {
      logo: applicationCard13,
      title: "Face Generator",
      pending: true,
    },
    {
      logo: applicationCard14,
      title: "Avatar Generator",
      pending: true,
    },
    {
      logo: applicationCard15,
      title: "3d Human Generator",
      pending: true,
    },
    {
      logo: applicationCard16,
      title: "Face Recognition",
      pending: true,
    },
  ],
  [
    {
      logo: applicationCard17,
      title: "Deep Face",
      pending: true,
    },
    {
      logo: applicationCard18,
      title: "Animation Creation",
      pending: true,
    },

    {
      logo: applicationCard19,
      title: "Text to image",
      pending: true,
    },
    {
      logo: applicationCard20,
      title: "Media Enhancer",
      pending: true,
    },
    {
      logo: applicationCard21,
      title: "Graphic Editor",
      pending: true,
    },
    {
      logo: applicationCard22,
      title: "Codefabric AI",
      pending: true,
    },
    {
      logo: applicationCard23,
      title: "Deck Generator",
      pending: true,
    },
    {
      logo: applicationCard24,
      title: "Prompt Generator",
      pending: true,
    },
  ],
];
const mobileCardsArray = [
  {
    logo: applicationCard1,
    title: "RedAI Art Bot",
    pending: false,
  },
  {
    logo: applicationCard2,
    title: "RedAI Meme Bot",
    pending: false,
  },

  {
    logo: applicationCard3,
    title: "RedAI QR Bot",
    pending: false,
  },
  {
    logo: applicationCard4,
    title: "RedAI Sound Bot",
    pending: false,
  },

  {
    logo: applicationCard5,
    title: "Trading Crypto Bot",
    pending: false,
  },
  {
    logo: applicationCard6,
    title: "Drug Discovery",
    pending: false,
  },

  {
    logo: applicationCard7,
    title: "RedAI Chat Bot",
    pending: true,
  },
  {
    logo: applicationCard8,
    title: "Manga Generator",
    pending: true,
  },

  {
    logo: applicationCard9,
    title: "3d Model Generator",
    pending: true,
  },
  {
    logo: applicationCard10,
    title: "Mind Script",
    pending: true,
  },

  {
    logo: applicationCard11,
    title: "Poster Generator",
    pending: true,
  },
  {
    logo: applicationCard12,
    title: "Voice synthesizer",
    pending: true,
  },

  {
    logo: applicationCard13,
    title: "Face Generator",
    pending: true,
  },
  {
    logo: applicationCard14,
    title: "Avatar Generator",
    pending: true,
  },

  {
    logo: applicationCard15,
    title: "3d Human Generator",
    pending: true,
  },
  {
    logo: applicationCard16,
    title: "Face Recognition",
    pending: true,
  },

  {
    logo: applicationCard17,
    title: "Deep Face",
    pending: true,
  },
  {
    logo: applicationCard18,
    title: "Animation Creation",
    pending: true,
  },

  {
    logo: applicationCard19,
    title: "Text to image",
    pending: true,
  },
  {
    logo: applicationCard20,
    title: "Media Enhancer",
    pending: true,
  },

  {
    logo: applicationCard21,
    title: "Graphic Editor",
    pending: true,
  },
  {
    logo: applicationCard22,
    title: "Codefabric AI",
    pending: true,
  },

  {
    logo: applicationCard23,
    title: "Deck Generator",
    pending: true,
  },
  {
    logo: applicationCard24,
    title: "Prompt Generator",
    pending: true,
  },
];

function MobileApplicationsSection() {
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <Container maxWidth="lg">
      <Box bgcolor={"transparent"}>
        <Swiper
          slidesPerView={1}
          //   pagination={{
          //     dynamicBullets: true,
          //   }}
          //   modules={[Pagination]}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {matches
            ? mobileCardsArray?.map(({ title, logo, pending }, index) => (
                <SwiperSlide key={title}>
                  <Grid
                    container
                    rowSpacing={6}
                    columnSpacing={3}
                    justifyContent={"center"}
                    my={4}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      key={title}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Box width={"180px"}>
                          <ApplicationsCards
                            logo={logo}
                            title={title}
                            pending={pending}
                            index={index}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              ))
            : cardsArray?.map((data, index) => (
                <SwiperSlide key={index * Math.random() + 1}>
                  <Grid
                    container
                    rowSpacing={6}
                    columnSpacing={3}
                    justifyContent={"center"}
                    my={4}
                  >
                    {data?.map(({ title, logo, pending }) => (
                      <Grid item xs={6} sm={4} md={3} key={title}>
                        <Box width={"180px"} margin={"auto"}>
                          <ApplicationsCards
                            logo={logo}
                            title={title}
                            pending={pending}
                            index={index}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </SwiperSlide>
              ))}

          <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
            <ChevronLeftIcon
              className="swiper-button-prev"
              style={{
                background: "#A201274D",
                boxShadow: "0px 10px 28.299999237060547px 0px #A201274D",
                width: matches ? "50px" : "60px",
                height: matches ? "50px" : "60px",
                borderRadius: "50px",
                cursor: "pointer",
                padding: "7px",
                position: "relative",
                color: "white",
              }}
            />
            <ChevronRightIcon
              className="swiper-button-next"
              style={{
                background: "#A201274D",

                boxShadow: "0px 10px 28.299999237060547px 0px #A201274D",
                width: matches ? "50px" : "60px",
                height: matches ? "50px" : "60px",
                borderRadius: "50px",
                marginLeft: "10px",
                cursor: "pointer",
                padding: "7px",
                position: "relative",
                color: "white",
              }}
            />
          </Box>
        </Swiper>
      </Box>
    </Container>
  );
}

export default MobileApplicationsSection;
