import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import background from "../Images/background.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

export default function UseCases() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box pb={10}>
        <Box
          sx={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: matches ? "100%" : "100% 100%",
            height: "570px",
            // backgroundAttachment: "fixed",
            margin: 0,
            padding: 0,
          }}
        >
          <Container maxWidth="lg">
            <div
              data-aos={matches ? "fade-up" : ""}
              data-aos-delay="20"
              data-aos-duration="1500"
            >
              <Typography
                pt={matches ? 5 : 35}
                sx={{
                  fontSize: matches ? "28px" : "55px",
                  fontFamily: "Extra Bold",
                  color: "#fff",
                }}
              >
                Infinite use cases and solutions for individuals, developers,
                and enterprises.
              </Typography>
            </div>
            <div
              data-aos={matches ? "fade-up" : ""}
              data-aos-delay="30"
              data-aos-duration="2000"
            >
              <Typography
                pt={3}
                sx={{
                  fontSize: matches ? "20px" : "24px",
                  fontFamily: "Regular",
                  color: "#808080",
                }}
              >
                With our technology, traders can make well-informed decisions by
                analyzing blockchain data using RedAI algorithms. Based on
                real-time data, this kind of AI trading can assist traders and
                investors in spotting trends, predicting market movements, and
                placing profitable trades.
              </Typography>
            </div>
            <div
              data-aos={matches ? "fade-up" : ""}
              data-aos-delay="40"
              data-aos-duration="3000"
            >
              <Box mt={5} display=" flex" alignItems="center">
                <a
                  href="https://presale.red-coin.org/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Box
                    mr={matches ? 2 : 4}
                    sx={{
                      width: "230px",
                      height: matches ? "50px" : "68px",
                      borderRadius: "32px",
                      background: "#A20127",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Regular",
                      color: "#fff",
                      fontSize: matches ? "14px" : "20px",
                      cursor: "pointer",
                    }}
                  >
                    PRESALE{" "}
                    <ArrowOutwardIcon
                      style={{
                        color: "#fff",
                        fontSize: "26px",
                        marginLeft: matches ? "5px" : "10px",
                      }}
                    />
                  </Box>
                </a>
                {/* <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Regular",
                  color: "#fff",
                  fontSize: matches ? "14px" : "20px",
                  cursor: "pointer",
                }}
              >
                LEARN MORE ABOUT TEAM
                <ArrowOutwardIcon
                  style={{
                    color: "red",
                    fontSize: "26px",
                    marginLeft: matches ? "5px" : "10px",
                  }}
                />
              </Typography> */}
              </Box>
            </div>
          </Container>
        </Box>
      </Box>
    </>
  );
}
