import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import StyledSwiper from "./SwiperSlider";
import character from "../Images/character.gif";
import setting from "../Images/setting.gif";
import lock from "../Images/lock.gif";
import reddot from "../Images/reddot.png";
import dots from "../Images/dots.png";

const swiperData = [
  {
    title: "AI RESEARCH",
    description:
      "Creating an open-source community with RedAI is intended to motivate researchers and developers of AI to share their models with a worldwide audience. Access to cutting-edge AI models created especially for the Blockchain, international, and cryptocurrency industries will be made easier as a result.",
    img: character,
  },
  {
    title: "AI CHARACTERS",
    description:
      "Using machine learning and natural language processing technology, an AI bot can create virtual characters that can interact with humans in a virtual world.",
    img: setting,
  },
  {
    title: "SAFEGUARDING",
    description:
      "RedAI uses the safest cryptographic primitives, encrypted communication, stores private keys securely, and integrates with hardware wallets, among other security measures, to defend against cyberattacks.",
    img: lock,
  },
];

const FeaturedSlider = () => {
  const matches = useMediaQuery("(max-width:960px)");

  return (
    <>
      <Box position="relative" zIndex={0} width="100%" mt={matches ? 0 : 10}>
        <Container maxWidth="xl">
          <StyledSwiper>
            {swiperData.map(({ title, description, img }, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{
                    background: "transparent",
                  }}
                >
                  <Grid
                    container
                    spacing={5}
                    mt={matches ? 0 : 10}
                    display="flex"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={6}>
                      <Box display="flex" alignItems="center">
                        <img src={reddot} width="32px" height="32px" alt="" />
                        <Typography
                          pl={1}
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Regular",
                            color: "#808080",
                          }}
                        >
                          TOKENIZED
                        </Typography>
                      </Box>
                      <Typography
                        mt={1}
                        sx={{
                          fontSize: matches ? "35px" : "65px",
                          fontFamily: "Extra Bold",
                          color: "#fff",
                          textAlign: "start",
                        }}
                      >
                        {title}
                      </Typography>
                      <Typography
                        mt={1}
                        sx={{
                          fontSize: "18px",
                          fontFamily: "Regular",
                          color: "#808080",
                          textAlign: "start",
                        }}
                      >
                        {description}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      display="flex"
                      justifyContent="center"
                    >
                      <img
                        src={img}
                        width={matches ? "96%" : "80%"}
                        style={{ borderRadius: "30px" }}
                        alt=""
                      />
                    </Grid>
                  </Grid>
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
          <Box
            display="flex"
            justifyContent={matches ? "center" : "flex-start"}
          >
            <img src={dots} width="50px" alt="" />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FeaturedSlider;
