import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import coin from "../Images/coin.png";
import token from "../Images/token.gif";
import mr1 from "../Images/mr1.png";
import mr2 from "../Images/mr2.webp";
import mr3 from "../Images/mr3.svg";
import bloom from "../Images/bloom.svg";
import aplogo from "../Images/aplogo.svg";
import digi from "../Images/digi.jpg";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Marquee from "react-fast-marquee";

function HeroSection() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box py={matches ? 5 : 10}>
        <Container maxWidth="xl">
          <Grid container spacing={3} display="flex" alignItems="center">
            <Grid item xs={12} md={6}>
              <div
                data-aos={matches ? "fade-down" : ""}
                data-aos-delay="20"
                data-aos-duration="1500"
              >
                <Typography
                  sx={{
                    fontSize: matches ? "34px" : "65px",
                    fontFamily: "Extra Bold",
                    color: "#fff",
                  }}
                >
                  Unlock your potential of Blockchain AI with RedAI
                </Typography>
              </div>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="50"
                data-aos-duration="3000"
              >
                <Box
                  mt={5}
                  display=" flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <a
                    href="https://presale.red-coin.org/"
                    target="_blank"
                    style={{ textDecoration: "none", width: "32%" }}
                    rel="noreferrer"
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: matches ? "55px" : "72px",
                        borderRadius: "18px",
                        background: "#A20127",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Regular",
                        color: "#fff",
                        fontSize: matches ? "14px" : "20px",
                        cursor: "pointer",
                      }}
                    >
                      PRESALE{" "}
                      <ArrowOutwardIcon
                        style={{
                          color: "#fff",
                          fontSize: "20px",
                          marginLeft: matches ? "5px" : "10px",
                        }}
                      />
                    </Box>
                  </a>
                  <Box
                    sx={{
                      width: "32%",
                      height: matches ? "55px" : "72px",
                      borderRadius: "18px",
                      background: "transparent",
                      border: "2px solid #A20127 ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Regular",
                      color: "#fff",
                      fontSize: matches ? "14px" : "20px",
                      cursor: "pointer",
                    }}
                  >
                    INTRO{" "}
                    <PlayCircleIcon
                      style={{
                        color: "#A20127",
                        fontSize: "20px",
                        marginLeft: matches ? "5px" : "10px",
                      }}
                    />
                  </Box>
                  <a
                    href="https://github.com/Coinsult/solidity/blob/main/Coinsult_RedAI_0x95...e667_Audit.pdf"
                    target="_blank"
                    style={{ textDecoration: "none", width: "32%" }}
                    rel="noreferrer"
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: matches ? "55px" : "72px",
                        borderRadius: "18px",
                        background: "#292929",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img src={coin} width={matches ? "65%" : "55%"} alt="" />
                    </Box>
                  </a>
                </Box>
              </div>
              <Box mt={5}>
                <Marquee
                  speed={50}
                  pauseOnClick={true}
                  pauseOnHover={true}
                  gradient={false}
                >
                  <Container maxWidth="md">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                        mt: 2,
                      }}
                    >
                      <a
                        href="https://finance.yahoo.com/news/redai-announces-presale-live-revolutionize-193000550.html"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          width: "130px",
                          marginRight: "30px",
                        }}
                        rel="noreferrer"
                      >
                        <img
                          src={mr1}
                          width="130px"
                          // style={{ marginRight: "30px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://www.bloomberg.com/press-releases/2023-11-09/redai-announces-its-presale-live-to-revolutionize-ai-and-blockchain-intersection-with-red"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          width: "130px",
                          marginRight: "30px",
                        }}
                        rel="noreferrer"
                      >
                        <img
                          src={bloom}
                          width="130px"
                          // style={{ marginRight: "30px" }}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://www.benzinga.com/pressreleases/23/11/35720666/redai-a-crypto-startup-raised-20m-for-redcoin-at-a-token-valuation-of-2-5-billion"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          width: "130px",
                          marginRight: "30px",
                        }}
                        rel="noreferrer"
                      >
                        <img src={mr2} width="130px" alt="" />
                      </a>
                      <a
                        href="https://www.marketwatch.com/press-release/redai-announces-its-presale-live-to-revolutionize-ai-and-blockchain-intersection-with-red-92f1a503?mod=search_headline"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          width: "160px",
                          marginRight: "60px",
                        }}
                        rel="noreferrer"
                      >
                        <img src={mr3} width="160px" alt="" />
                      </a>
                      <a
                        href="https://apnews.com/press-release/ein-presswire-newsmatics/artificial-intelligence-067ec1d70f35bd90d6b3e30e0a39c4b5"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          width: "130px",
                          marginRight: "30px",
                        }}
                        rel="noreferrer"
                      >
                        <img src={aplogo} width="50px" alt="" />
                      </a>
                      <a
                        href="https://www.digitaljournal.com/pr/news/binary-news-network/crypto-startup-redai-raised-20m-for-redcoin-at-2-5b-token-valuation"
                        target="_blank"
                        style={{ textDecoration: "none", width: "160px" }}
                        rel="noreferrer"
                      >
                        <img
                          src={digi}
                          width="160px"
                          style={{ marginRight: "30px" }}
                          alt=""
                        />
                      </a>
                    </Box>
                  </Container>
                </Marquee>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              justifyContent="center"
              mt={matches && 5}
            >
              <img src={token} width="80%" alt="" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default HeroSection;
