import {
  Badge,
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import logo from "../Images/logo.png";
import bar1 from "../Images/bar1.png";
import bar2 from "../Images/bar2.png";
import spring from "../Images/spring.gif";
import maya from "../Images/maya.gif";

export default function RedFoundation() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Container maxWidth="xl">
        <Box py={matches ? 5 : 10} px={matches ? 0 : 5}>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={matches ? 2 : 8}
          >
            <Typography
              sx={{
                fontSize: matches ? "32px" : "65px",
                fontFamily: "Extra Bold",
                color: "#fff",
              }}
            >
              RedAI Foundation
            </Typography>
            {/* <img width="80px" src={logo} alt="" /> */}
          </Box>
          <Grid container spacing={5} mt={1}>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img width="80%" src={bar1} alt="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Box
                  sx={{
                    backgroundImage: `url(${spring})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: matches ? "100% 100%" : "100% 100%",
                    borderRadius: "50%",
                    height: matches ? "300px" : "400px",
                    // backgroundAttachment: "fixed",
                    margin: 0,
                    p: 5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    mt={0}
                    p={3}
                    sx={{
                      background: "rgb(45,45,45, 0.6)",
                      width: matches ? "100%" : "90%",
                      borderRadius: "26px",
                      position: "relative",
                      zIndex: 0,
                    }}
                  >
                    <Box
                      position="absolute"
                      zIndex={1}
                      top="-50px"
                      left="-30px"
                      sx={{
                        width: "80px",
                        height: "80px",
                        background: "rgb(45,45,45)",
                        borderRadius: "50%",
                        fontFamily: "Regular",
                        color: "#fff",
                        fontSize: matches ? "18px" : "24px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      20M+
                    </Box>
                    <Typography
                      sx={{
                        fontSize: matches ? "18px" : "30px",
                        fontFamily: "Regular",
                        color: "#fff",
                      }}
                    >
                      RedAI increased expectations for future success by team
                      raising over $20 million before the project even got
                      underway.
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img width="80%" src={bar2} alt="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Box
                  sx={{
                    backgroundImage: `url(${maya})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: matches ? "100% 100%" : "100% 100%",
                    borderRadius: "50%",
                    height: matches ? "300px" : "400px",
                    // backgroundAttachment: "fixed",
                    margin: 0,
                    p: 5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    mt={0}
                    p={3}
                    sx={{
                      background: "rgb(45,45,45, 0.6)",
                      width: matches ? "100%" : "90%",
                      borderRadius: "26px",
                      position: "relative",
                      zIndex: 0,
                    }}
                  >
                    <Box
                      position="absolute"
                      zIndex={1}
                      top="-50px"
                      left="-30px"
                      sx={{
                        width: "80px",
                        height: "80px",
                        background: "rgb(45,45,45)",
                        borderRadius: "50%",
                        fontFamily: "Regular",
                        color: "#fff",
                        fontSize: matches ? "18px" : "24px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography fontSize="10px">TARGETING</Typography>
                      50M+
                    </Box>
                    <Typography
                      sx={{
                        fontSize: matches ? "18px" : "30px",
                        fontFamily: "Regular",
                        color: "#fff",
                      }}
                    >
                      Prior to the halving of Bitcoin, Red AI hopes to raise
                      over $50 million to provide liquidity and get ready for
                      future marketing initiatives.
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
        <Divider
          sx={{ backgroundColor: "#262626", width: "100%", height: "1px" }}
        />
      </Container>
    </>
  );
}
