import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  communityBg,
  investmentBg,
  scalabilityBg,
} from "../components/SmallComponents/Images";

const contentArray = [
  {
    logo: investmentBg,
    head: "Investment",
    para: "We create new market opportunities by lowering the AI-Apps' infrastructure and technical know-how requirements.",
  },
  {
    logo: scalabilityBg,
    head: "Scalability",
    para: "To guarantee execution scalability and do away with vendor lockup, we employ blockchain technologies.",
  },
  {
    logo: communityBg,
    head: "Community",
    para: "We promote fair market competition as a necessary component of developing large, dynamic, and cooperative communities.",
  },
];

function CoreElement() {
  const matches = useMediaQuery("(max-width:950px)");
  return (
    <Box mt={{ xs: 10, sm: 18 }}>
      <Container maxWidth="xl">
        <Typography
          sx={{
            fontSize: matches ? "32px" : "65px",
            fontFamily: "Extra Bold",
            color: "#fff",
          }}
        >
          RedAI Key Components
        </Typography>
        <Typography
          mt={1}
          sx={{
            fontSize: matches ? "18px" : "22px",
            fontFamily: "Regular",
            color: "#808080",
          }}
        >
          We harness the power of blockchain, advanced cryptography, and novel
          infracstructure to create a new foundation for AI-Apps.
        </Typography>

        <Box>
          <Grid container spacing={4} my={4} justifyContent={"center"}>
            {contentArray.map(({ logo, head, para }, i) => (
              <Grid item xs={12} sm={6} lg={4} key={head}>
                <Box height={"100%"}>
                  <Box
                    sx={{
                      borderRadius: "12px",
                      px: 2,
                      py: 3,
                      background: "#141414",
                      height: "100%",
                      "&:hover": {
                        background: "#A20127",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        component={"img"}
                        alt=""
                        src={logo}
                        width={"200px"}
                      />
                    </Box>
                    <Box mt={{ xs: 0, sm: i === 2 ? 0 : -3 }}>
                      <Typography
                        sx={{
                          fontSize: "22px",
                          fontFamily: "Regular",
                          color: "#fff",
                        }}
                      >
                        {head}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontFamily: "Regular",
                          color: "#808080",
                          width: { xs: "100%", sm: "70%" },
                          mt: 1,
                        }}
                      >
                        {para}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default CoreElement;
