import React from "react";
import {
  Container,
  Hidden,
  useMediaQuery,
  SwipeableDrawer,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { logo } from "./SmallComponents/Images";

// import { AppContext } from "../utils";
import LanguageIcon from "@mui/icons-material/Language";
import { StyledText } from "./SmallComponents/AppComponents";
import { ExampleButton } from "./SmallComponents/StyledWalletButton";
import { useState } from "react";
import { LanguageHover } from "./SmallComponents/LanguageHover";

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#000000 !important",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

const mobileDataarr = [
  {
    name: "SOLUTIONS",
    link: "/",
  },
  {
    name: "PRESALE",
    link: "https://presale.red-coin.org/",
  },
  {
    name: "DOCUMENTS",
    link: "/whitePaper.pdf",
  },
  {
    name: "TEAM",
    link: "/",
  },
  {
    name: "COMMUNITY",
    link: "/",
  },
  {
    name: "CONTACT",
    link: "/",
  },
];
export default function Header() {
  // const { account, connect, disconnect } = useContext(AppContext);
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1350px)");
  const [language, setLanguage] = useState(false);
  const handleLng = () => {
    setLanguage(true);
  };
  const handleLngFalse = () => {
    setLanguage(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={5}
        px={2}
      >
        <Box display="flex" alignItems="center">
          <img width="43px" src={logo} alt="" />
          <Typography
            sx={{
              fontFamily: "Regular",
              fontSize: matches ? "20px" : "30px",
              pl: 1,
              color: "#fff",
            }}
          >
            RedAI Core
          </Typography>
        </Box>
        {/* <Hidden lgUp>
          <Button
            onMouseEnter={handleLng}
            onMouseLeave={handleLngFalse}
            className="bg-[ #272727] mr-3 mt-2 rounded-full hidden lg:flex relative"
          >
            <LanguageIcon
              style={{
                color: "#fff",
                fontSize: "26px",
                marginRight: "15px",
                position: "relative",
              }}
            />
            {language ? <LanguageHover /> : " "}
          </Button>
        </Hidden> */}
      </Box>
      <List>
        {mobileDataarr.map(({ name, link }, index) => (
          <ListItem
            button
            style={{
              justifyContent: "center",
            }}
            key={index}
          >
            <a
              href={link}
              target="_blank"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <ListItemText
                style={{
                  textTransform: "uppercase",
                  textAlign: "center",
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#ffffff",
                  fontFamily: "Regular",
                }}
              >
                {name}
              </ListItemText>
            </a>
          </ListItem>
        ))}
      </List>
      <Box mb={1} display="flex" justifyContent="center">
        <ExampleButton />
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          background: "transparent",
        }}
        // height="92px"
        width="100%"
        py={1.5}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <img width="43px" src={logo} alt="" />
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: matches ? "20px" : "30px",
                  pl: 1,
                  color: "#fff",
                }}
              >
                RedAI Core
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Hidden lgDown>
                <StyledText mr={5}>SOLUTIONS</StyledText>
                <a
                  href="https://presale.red-coin.org/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <StyledText mr={5} display="flex">
                    PRESALE{" "}
                    <Box
                      ml={1}
                      style={{
                        width: "50px",
                        height: "25px",
                        background: "#86EFAC",
                        borderRadius: "56px",
                        color: "#000",
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      LIVE
                    </Box>
                  </StyledText>
                </a>

                <a
                  href="/whitePaper.pdf"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <StyledText mr={5}>DOCUMENTS</StyledText>
                </a>
                <StyledText mr={5}>TEAM</StyledText>
                <StyledText mr={5}>COMMUNITY</StyledText>
                <StyledText>CONTACT</StyledText>
              </Hidden>

              <Hidden lgUp>
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      onClick={toggleDrawer(anchor, true)}
                      style={{ zIndex: 1 }}
                    >
                      <MenuIcon
                        style={{
                          fontSize: "28px",
                          cursor: "pointer",
                          color: "#fff",
                        }}
                      ></MenuIcon>
                    </Button>
                    <Paper>
                      <SwipeableDrawer
                        classes={{ paper: classes.paper }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        {list(anchor)}
                      </SwipeableDrawer>
                    </Paper>
                  </React.Fragment>
                ))}
              </Hidden>
            </Box>
            <Hidden lgDown>
              <Box display="flex" alignItems="center">
                <ExampleButton />
              </Box>
            </Hidden>
          </Box>
        </Container>
      </Box>
    </>
  );
}
