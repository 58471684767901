import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import reddot from "../Images/reddot.png";
import violet from "../Images/violet.mp4";
import red from "../Images/red.mp4";
import blue from "../Images/blue.mp4";
import yellow from "../Images/yellow.mp4";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

export default function Foundation() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box py={matches ? 5 : 10}>
        <Container maxWidth="md">
          <div
            data-aos={matches ? "fade-up" : ""}
            data-aos-delay="30"
            data-aos-duration="2000"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={reddot} width="32px" height="32px" alt="" />
              <Typography
                pl={1}
                sx={{
                  fontSize: "16px",
                  fontFamily: "Regular",
                  color: "#808080",
                }}
              >
                THE FOUNDATION
              </Typography>
            </Box>
            <Typography
              mt={1}
              sx={{
                fontSize: matches ? "40px" : "65px",
                fontFamily: "Extra Bold",
                color: "#fff",
                textAlign: "center",
              }}
            >
              RedAI Core
            </Typography>

            <Typography
              mt={1}
              sx={{
                fontSize: "22px",
                fontFamily: "Regular",
                color: "#808080",
                textAlign: "center",
              }}
            >
              RedAI uses a generative model to provide text-based responses to
              user inputs. The model gains an understanding of the relationships
              and patterns present in human language through training on a vast
              corpus of text data.
            </Typography>
          </div>
        </Container>
        <Container maxWidth="xl">
          <Grid container spacing={5} mt={5}>
            <Grid item xs={12} md={3}>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Box
                  p={2}
                  sx={{
                    "&:hover": {
                      background: "#141414",
                      borderRadius: "20px",
                    },
                  }}
                >
                  <Typography
                    mb={2}
                    sx={{
                      fontSize: "22px",
                      fontFamily: "Regular",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    RedAI Core
                  </Typography>
                  <Box display="flex" justifyContent="center" my={7}>
                    <video
                      src={violet}
                      style={{ borderRadius: "50%" }}
                      width="60%"
                      autoPlay
                      muted
                      loop
                      type="video/mp4"
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <ArrowOutwardIcon
                      style={{
                        color: "#A20127",
                        fontSize: "25px",
                      }}
                    />
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Box
                  p={2}
                  sx={{
                    "&:hover": {
                      background: "#141414",
                      borderRadius: "20px",
                    },
                  }}
                >
                  <Typography
                    mb={2}
                    sx={{
                      fontSize: "22px",
                      fontFamily: "Regular",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Real-time Trading
                  </Typography>
                  <Box display="flex" justifyContent="center" my={7}>
                    <video
                      src={red}
                      style={{ borderRadius: "50%" }}
                      width="60%"
                      autoPlay
                      muted
                      loop
                      type="video/mp4"
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <ArrowOutwardIcon
                      style={{
                        color: "#A20127",
                        fontSize: "25px",
                      }}
                    />
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Box
                  p={2}
                  sx={{
                    "&:hover": {
                      background: "#141414",
                      borderRadius: "20px",
                    },
                  }}
                >
                  <Typography
                    mb={2}
                    sx={{
                      fontSize: "22px",
                      fontFamily: "Regular",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Smart Alerts
                  </Typography>
                  <Box display="flex" justifyContent="center" my={7}>
                    <video
                      src={blue}
                      style={{ borderRadius: "50%" }}
                      width="60%"
                      autoPlay
                      muted
                      loop
                      type="video/mp4"
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <ArrowOutwardIcon
                      style={{
                        color: "#A20127",
                        fontSize: "25px",
                      }}
                    />
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                data-aos={matches ? "fade-up" : ""}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Box
                  p={2}
                  sx={{
                    "&:hover": {
                      background: "#141414",
                      borderRadius: "20px",
                    },
                  }}
                >
                  <Typography
                    mb={2}
                    sx={{
                      fontSize: "22px",
                      fontFamily: "Regular",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Contract Generator
                  </Typography>
                  <Box display="flex" justifyContent="center" my={7}>
                    <video
                      src={yellow}
                      style={{ borderRadius: "50%" }}
                      width="60%"
                      autoPlay
                      muted
                      loop
                      type="video/mp4"
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <ArrowOutwardIcon
                      style={{
                        color: "#A20127",
                        fontSize: "25px",
                      }}
                    />
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
